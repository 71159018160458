import React from 'react'
import Museum from "./Museum";
import Navbar from "../components/Navbar";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom'

class HomePin extends React.Component {
  handleSubmit = e => {
    e.preventDefault()

    const password = e.target.password.value
    mockupAuth.authenticate({ password })
    this.setState(prevState => ({})) //force update
  }

  render() {
    if (mockupAuth.isAuthenticated) {
      return <Redirect to="/museum/1/0" />
    }
    return (
      <>
        <Navbar />
        <div className="home_container">
          <div className="home_card1 ">
            <img
              id="logoags"
              src="https://res.cloudinary.com/hyl31wapk/image/upload/v1615464750/logooooo_qatxyu_wjipmh.webp"
              alt="logo"
            />
            <form className="form-log" onSubmit={e => this.handleSubmit(e)}>

              <input className="log" type="password" name="password" placeholder="Lozinka" /><br />
              <button className="btn-log">Login</button>
            </form>
          </div>
        </div>
      </>
    )
  }
}

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return mockupAuth.isAuthenticated ? (
        <Component {...props} />
      ) : (
          <Redirect to="/login" />
        )
    }}
  />
)

export const mockupAuth = {
  isAuthenticated: false,
  authenticate(cb) {
    if (cb.password === '2021') {
      this.isAuthenticated = true
    } else {
      this.isAuthenticated = false
    }
  }
}

export function View() {
  return (
    <Router>
      <Switch>
        <Route exact path="/lock" component={HomePin} />
        <Route path="/lock" component={HomePin} />
        <PrivateRoute
          path="/museum/0/0"
          component={Museum}
          isAuthenticated={mockupAuth.isAuthenticated}
        />
      </Switch>
    </Router>
  )
}

export default HomePin
