export default {
  title:
    "Digital storytelling platform AUDIOGUIDE.hr brings an innovative and easy way of exploring tourist destinations and cultural heritage worldwide.",
  title2: "Available on all devices",
  title3: "Try QR CODE ",
  title4: "or go to MENU",
  menu: "Home",
  menu0: "Split - West Coast",
  menu1: "Schönbrunn Castle",
  menu2: "Museum MERN",
  menu3: "About Us",
  about1:
    "Our products and services are created to meet your needs, following trends of innovative digital solutions in tourism.",
  about2:
    "Mobile technology enables a new and simple way of exploring, engaging and discovering arts and culture. In few clicks Audioguide.hr becomes your personal guide and storyteller.",
  about3:
    "Our mission is digital technology implementation in the field of tourism, aiming for a higher quality services and gaining the trust and satisfactions of users. ",
  about4: "We wish you a pleasant visit!",
};
