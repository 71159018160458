export default {
  title:
    "Digitalna platforma AUDIOGUIDE.hr omogućuje vam upoznavanje turističkih destinacija i kulturne baštine na nov i jednostavan način.",
  title2: "Dostupno na svim uređajima",
  title3: "Pokušajte s QR kodom",
  title4: "ili idite na izbornik",
  menu: "Početna",
  menu0: "Split - Zapadna obala",
  menu1: "Dvorac Schönbrunn",
  menu2: "Muzej Vasa",
  menu3: "O nama",
  about1:
    "Naši proizvodi i usluge kreirani su u skladu s potrebama suvremenog turista, prateći trendove inovativnih digitalnih rješenja u turizmu.",
  about2:
    "Korištenje mobilne tehnologije omogućuje vam upoznavanje umjetnosti i kulture na potpuno nov i jednostavan način. Audioguide.hr u svega nekoliko klikova postaje vaš osobni kustos u muzeju, vodič i interpretator baštine.",
  about3:
    "Naša misija je implementirati digitalnu tehnologiju na turističkom tržištu s ciljem poboljšanja kvalitete usluge i postizanja povjerenja i zadovoljstva korisnika.",
  about4: "Želimo vam ugodan posjet!",
};
