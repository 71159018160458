import React, { Component } from "react";
import Navbar from "../components/Navbar";
import counterpart from "counterpart";
import Translate from "react-translate-component";
import en from "./lng/eng";
import hr from "./lng/hr";
import de from "./lng/de";
import spa from "./lng/spa";
import pl from "./lng/pl";
import ita from "./lng/ita";

counterpart.registerTranslations("en", en);
counterpart.registerTranslations("hr", hr);
counterpart.registerTranslations("de", de);
counterpart.registerTranslations("spa", spa);
counterpart.registerTranslations("pl", pl);
counterpart.registerTranslations("ita", ita);
counterpart.setLocale("en");

class About extends Component {
  state = {
    lang: "en",
  };

  onLangChange = (e) => {
    this.setState({ lang: e.target.value });
    counterpart.setLocale(e.target.value);
  };

  render() {
    return (
      <>
        <Navbar />
        <div id="language">
          <select value={this.state.lang} onChange={this.onLangChange}>
            <option value="en">🇬🇧</option>
            <option value="hr">🇭🇷</option>
            <option value="de">🇩🇪</option>
            <option value="spa">🇪🇸</option>
            <option value="pl">🇵🇱</option>
            <option value="ita">🇮🇹</option>
          </select>
        </div>
        <div className="about_container">
          <img
            className="logo2"
            src="https://res.cloudinary.com/hyl31wapk/image/upload/v1615464871/lg_r1ru1y_1_wyvkw6_uw6bqd.webp"
            alt="logo"
          />
          <div className="about_card1">
            <h3>
              TOUR GUIDE SYSTEM d.o.o. (Ltd)
              <br />
              Supilova 38, 21000 Split – Croatia
              <br />
              VAT ID: HR00164518268 <br />
              E-mail: info@tourguidesystem.hr <br />
              Web:{" "}
              <a href="http://tourguidesystem.hr/">www.tourguidesystem.hr</a>
            </h3>
          </div>
          <div className="about_card2">
            <Translate content="about1" component="h3" className="class" />
          </div>
          <div className="about_card2">
            <Translate content="about2" component="h3" className="class" />
          </div>
          <div className="about_card2">
            <Translate content="about3" component="h3" className="class" />
          </div>
          <div className="about_card3">
            <Translate content="about4" component="h3" className="class" />
          </div>
        </div>
      </>
    );
  }
}

export default About;
