import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import Translate from "react-translate-component";

export const SidebarData = [
  {
    title: <Translate content="menu" component="h3" className="class" />,
    path: "/",
    icon: <AiIcons.AiFillHome />,
    cName: "nav-text",
  },
  {
    title: <Translate content="menu0" component="h3" className="class" />,
    path: "/museum/0/0",
    icon: <FaIcons.FaArchway />,
    cName: "nav-text",
  },
  {
    title: <Translate content="menu1" component="h3" className="class" />,
    path: "/lock",
    icon: <FaIcons.FaArchway />,
    cName: "nav-text",
  },
  {
    title: <Translate content="menu2" component="h3" className="class" />,
    path: "/lockMern",
    icon: <FaIcons.FaArchway />,
    cName: "nav-text",
  },

  {
    title: <Translate content="menu3" component="h3" className="class" />,
    path: "/about",
    icon: <AiIcons.AiOutlineInfoCircle />,
    cName: "nav-text",
  },
];
