import React, { Component } from "react";
import Navbar from "../components/Navbar";
import QrReader from "react-qr-reader";
import { navigate } from "@reach/router";

class Qr extends Component {
  handleScan = (data) => {
    if (data) {
      navigate(data);
    }
  };
  handleError = (err) => {
    console.error(err);
  };
  render() {
    return (
      <>
        <Navbar />
        <div className="home_container">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 100,
            }}
          >
            <QrReader
              delay={200}
              onError={this.handleError}
              onScan={this.handleScan}
              style={{ width: 300, height: 300 }}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Qr;
