export default {
  title:
    "La plataforma de narración digital AUDIOGUIDE.hr ofrece una forma innovadora y sencilla de explorar destinos turísticos y el patrimonio cultural de todo el mundo.",
  title2: "Disponible en todos los dispositivos",
  title3: "Pruebe el código QR",
  title4: "o vaya al menú",
  menu: "Inicio",
  menu0: "Split - Costa oeste",
  menu1: "Schönbrunn Castle",
  menu2: "Museum Vasa",
  menu3: "Sobre nosotros",
  about1:
    "Nuestros productos y servicios están creados para satisfacer sus necesidades, siguiendo las tendencias de soluciones digitales innovadoras en el turismo.",
  about2:
    "La tecnología móvil permite una forma nueva y sencilla de explorar, involucrar y descubrir las artes y la cultura. Con unos pocos clics Audioguide.hr se convierte en su guía y narrador personal.",
  about3:
    "Nuestra misión es la implementación de tecnología digital en el ámbito del turismo, buscando una mayor calidad en los servicios y ganando la confianza y satisfacción de los usuarios.",
  about4: "Le deseamos una agradable visita!",
};
