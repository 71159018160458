import React from "react";
import Navbar from "../components/Navbar";
import "../App.css";
import data from "../components/data_new.json";
import Fade from "react-reveal/Fade";
import ReactPlayer from "react-player";
import AudioPlayer from "react-h5-audio-player";
import nl2br from 'react-newline-to-break';
import { hideAll } from "react-reveal/globals";

class Museum extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      museum: this.props.match.params.id,
      default_lng: data[this.props.match.params.id].default_lng,
    };
    this.handleChange = this.handleChange.bind(this);
    this.showNextPage = this.showNextPage.bind(this);
    this.showPreviousPage = this.showPreviousPage.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  handleChange(e) {
    const newId = e.target.value;
    this.setState({
      id: newId,
      selectedValue: newId,
    });
  }

  showNextPage(e) {
    this.setState({
      id: parseInt(e.target.value) + 1,
    });
  }

  showPreviousPage(e) {
    this.setState({
      id: parseInt(e.target.value) - 1,
    });
  }

  changeLanguage(e) {
    const language = e.target.value;
    this.setState({
      default_lng: language,
      selectedValue: language,
    });
  }
  componentDidMount() {
    const { eksponat } = this.props.match.params;
    if (eksponat) {
      this.setState({
        id: eksponat,
        selectedValue: eksponat,
      });
    }
    console.log(eksponat);
  }

  render() {
    const { id, museum, lng, default_lng } = this.state;

    const name_eng = data[museum] && data[museum].name_eng;
    const name_ger = data[museum] && data[museum].name_ger;
    const name_pol = data[museum] && data[museum].name_pol;
    const name = data[museum] && data[museum].name;
    const text = data[museum].exponents[id] && data[museum].exponents[id].text;
    const text_eng = data[museum].exponents[id] && data[museum].exponents[id].text_eng;
    const text_ger = data[museum].exponents[id] && data[museum].exponents[id].text_ger;
    const text_pol = data[museum].exponents[id] && data[museum].exponents[id].text_pol;
    const description = data[museum].exponents[id] && data[museum].exponents[id].description;
    const description_eng = data[museum].exponents[id] && data[museum].exponents[id].description_eng;
    const description_ger = data[museum].exponents[id] && data[museum].exponents[id].description_ger;
    const description_pol = data[museum].exponents[id] && data[museum].exponents[id].description_pol;
    const audio = data[museum].exponents[id] && data[museum].exponents[id].audio;
    const audio_eng = data[museum].exponents[id] && data[museum].exponents[id].audio_eng;
    const audio_ger = data[museum].exponents[id] && data[museum].exponents[id].audio_ger;
    const audio_pol = data[museum].exponents[id] && data[museum].exponents[id].audio_pol;
    const video = data[museum].exponents[id] && data[museum].exponents[id].video;
    const img = data[museum] && data[museum].img;
    const subtext = data[museum].exponents[id] && data[museum].exponents[id].subtext;
    const subtext_eng = data[museum].exponents[id] && data[museum].exponents[id].subtext_eng;

    return (
      <>
        <Navbar />
        <div id="language">
          <select onChange={this.changeLanguage} value={lng}>
            <>
              {name && <option value="hr">🇭🇷</option>}
              {name_eng && <option value="eng">🇬🇧</option>}
              {name_ger && <option value="ger">🇩🇪</option>}
              {name_pol && <option value="pol">🇵🇱</option>}
            </>
          </select>
        </div>
        <div className="sticky" style={{ backgroundImage: `url(${img})` }}>

          <Fade>
            <div className="name">
              <h1>
                {(default_lng === "hr" && name) ||
                  (default_lng === "eng" && name_eng) ||
                  (default_lng === "ger" && name_ger) ||
                  (default_lng === "pol" && name_pol)}
              </h1>
            </div>
          </Fade>
          <div className="museum">
            <select onChange={this.handleChange} value={id}>
              {data[museum].exponents.map((exponent) => (
                <option value={exponent.id} key={exponent.id}>
                  {exponent.id}
                </option>
              ))}
            </select>
            <div className="play">
              <button
                onClick={(id >= 1 && this.showPreviousPage) || null}
                className="previous"
                value={id}
              >
                &#x2039;
              </button>

              <AudioPlayer
                src={
                  (default_lng === "hr" && audio) ||
                  (default_lng === "eng" && audio_eng) ||
                  (default_lng === "ger" && audio_ger) ||
                  (default_lng === "pol" && audio_pol)
                }
                onPlay={(e) => console.log("onPlay")}
                autoPlay
              />

              <button
                onClick={
                  (id + 1 < data[museum].exponents.length &&
                    this.showNextPage) ||
                  null
                }
                className="next"
                value={id}
              >
                &#8250;
              </button>
            </div>
          </div>
        </div>

        <div className="conteiner_description">
          <div className="museum_description">
            <Fade>
              <h1 className="title">
                {(default_lng === "hr" && text) ||
                  (default_lng === "eng" && text_eng) ||
                  (default_lng === "ger" && text_ger) ||
                  (default_lng === "pol" && text_pol)}
              </h1>
              <h1 className="subtitle">
                {(default_lng === "hr" && subtext) ||
                  (default_lng === "eng" && subtext_eng) ||
                  (default_lng === "ger" && text_ger) ||
                  (default_lng === "pol" && text_pol)}
              </h1>
              <div className="museum-description">
                <h3 className="text">
                  {(default_lng === "hr" && description) ||
                    (default_lng === "eng" && description_eng) ||
                    (default_lng === "ger" && description_ger) ||
                    (default_lng === "pol" && description_pol)}
                </h3>
              </div>
              <>
                {video && (
                  <div className="videoPlayer">
                    <ReactPlayer
                      url={`/matrice/${video}`}
                      playing
                      controls
                      width={340}
                      height={240}
                    />
                  </div>
                )}
              </>
            </Fade>
          </div>
        </div>
      </>
    );
  }
}
export default Museum;
