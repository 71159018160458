import React from "react";
import "./App.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePin from "./pages/HomePin";
import HomePinMern from "./pages/HomePinMERN";
import Home from "./pages/Home";
import About from "./pages/About";
import Museum from "./pages/Museum";
import Footer from "./components/Footer";
import NotFound from "./pages/NotFound";
import Qr from "./pages/Qr";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/lock" component={HomePin} />
          <Route path="/lockMern" component={HomePinMern} />
          <Route path="/qr" component={Qr} />
          <Route path="/museum/:id/:eksponat" component={Museum} />
          <Route path="/about" component={About} />
          <Route path="/notfound" component={NotFound} />
          <Route component={NotFound} />
        </Switch>
      </Router>
      <Footer />
    </>
  );
}

export default App;
