import React from "react";
import Navbar from "../components/Navbar";

function NotFound() {
  return (
    <>
      <Navbar />
      <div className="home_container">
        <img
          id="notFound"
          src="https://res.cloudinary.com/hyl31wapk/image/upload/v1604072297/404_2_bmb7qg_1_qs6k35.png"
          alt="logo"
        />
      </div>
    </>
  );
}

export default NotFound;
