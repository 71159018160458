import React, { Component } from "react";
import Navbar from "../components/Navbar";
import counterpart from "counterpart";
import Translate from "react-translate-component";
import en from "./lng/eng";
import hr from "./lng/hr";
import de from "./lng/de";
import spa from "./lng/spa";
import pl from "./lng/pl";
import ita from "./lng/ita";
import { DiApple, DiAndroid, DiWindows } from "react-icons/di";
import { ImQrcode } from "react-icons/im";

counterpart.registerTranslations("en", en);
counterpart.registerTranslations("hr", hr);
counterpart.registerTranslations("de", de);
counterpart.registerTranslations("spa", spa);
counterpart.registerTranslations("pl", pl);
counterpart.registerTranslations("ita", ita);
counterpart.setLocale("en");

class Home extends Component {
  state = {
    lang: "en",
  };

  onLangChange = (e) => {
    this.setState({ lang: e.target.value });
    counterpart.setLocale(e.target.value);
  };

  render() {
    return (
      <>
        <Navbar />
        <div id="language">
          <select value={this.state.lang} onChange={this.onLangChange}>
            <option value="en">🇬🇧</option>
            <option value="hr">🇭🇷</option>
            <option value="de">🇩🇪</option>
            <option value="spa">🇪🇸</option>
            <option value="pl">🇵🇱</option>
            <option value="ita">🇮🇹</option>
          </select>
        </div>
        <div className="home_container">
          <div className="home_card1">
            <img
              id="logoags"
              src="https://res.cloudinary.com/hyl31wapk/image/upload/v1615464750/logooooo_qatxyu_wjipmh.webp"
              alt="logo"
            />
            <Translate content="title" component="h3" className="class" />
          </div>
          <div className="home_cardQR">
            <Translate content="title3" component="h3" className="class" />
            <div className="QRicon">
              <a href="/qr">
                <ImQrcode />
              </a>
            </div>
            <Translate content="title4" component="h3" className="class" />
          </div>
          <div className="device_botom">
            <div className="home_card2">
              <Translate content="title2" component="h3" className="class" />
              <div className="devices">
                <DiApple />
                &nbsp;&nbsp;
                <DiAndroid />
                &nbsp;&nbsp;
                <DiWindows />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Home;
