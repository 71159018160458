export default {
    title:
        "La piattaforma digitale AUDIOGUIDE.hr offre la possibilità di conoscere le destinazioni turistiche e il loro patrimonio culturale in maniera semplice ed innovativa.",
    title2: "Disponibile su tutti i dispositivi",
    title3: "Accedi con il codice QR",
    title4: "o vai al MENU",
    menu: "Home",
    menu0: "Split - Costa ovest",
    menu1: "Dvorac Schönbrunn",
    menu2: "Muzej MERN",
    menu3: "Chi siamo",
    about1:
        "I nostri prodotti e servizi sono creati per soddisfare le esigenze del turista moderno, seguendo gli innovativi trend digitali del settore turistico.",
    about2:
        "La tecnologia mobile ti permette di scoprire l'arte e la cultura in un modo semplice e del tutto nuovo. In pochi click Audioguide.hr diventa la tua guida personale ai musei d’arte e al patrimonio culturale del luogo.",
    about3:
        "La nostra missione è implementare la tecnologia digitale nel settore turistico per migliorare la qualità del servizio e ottenere la fiducia e la soddisfazione dei nostri utenti.",
    about4: "Ti auguriamo una piacevole visita!",
};
