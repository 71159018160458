import React from "react";
import "../App.css";

const Footer = () => (
  <footer className="footer">
    <p>
      AUDIOGUIDE.hr member of{" "}
      <a href="http://tourguidesystem.hr/">Tour Guide System d.o.o.</a>
    </p>
    <p>All Rights Reserved © 2020</p>
  </footer>
);
export default Footer;
