export default {
    title:
        "Platforma cyfrowa AUDIOGUIDE.hr umożliwia w nowy i prosty sposób, poznanie destynacji turystycznych i dziedzictwa kulturowego.",
    title2: "Dostępne na wszystkich urządzeniach",
    title3: "Wypróbuj kod QR",
    title4: "ub przejdź do MENU",
    menu: "Strona główna",
    menu0: "Split - Zachodnie Wybrzeże",
    menu1: "Dvorac Schönbrunn",
    menu2: "Muzej MERN",
    menu3: "O nas",
    about1:
        "Nasze produkty i usługi tworzymy zgodnie z potrzebami współczesnych turystów, podążając za trendami innowacyjnych rozwiązań cyfrowych w turystyce.",
    about2:
        "Korzystanie z technologii mobilnej pozwala na poznanie sztuki i kultury w zupełnie nowy i łatwy sposób. Wystarczy kilka kliknięć, by Audioguide.hr stał się Twoim osobistym kustoszem w muzeum, przewodnikiem i interpretatorem dziedzictwa.",
    about3:
        "Naszą misją jest wdrażanie technologii cyfrowej na rynku turystycznym w celu poprawy jakości obsługi oraz zdobycia zaufania i satysfakcji klientów.",
    about4: "Życzymy przyjemnej wizyty!",
};
