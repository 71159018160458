export default {
  title:
    "Die digitale Storytelling-Plattform AUDIOGUIDE.hr bietet eine innovative und einfache Möglichkeit, touristische Ziele und das kulturelle Erbe weltweit zu erkunden.",
  title2: "Auf allen Geräten verfügbar",
  title3: "Aktivieren Sie den QR Code",
  title4: "oder gehen Sie in das Menü",
  menu: "Startseite",
  menu0: "Split - Westküste",
  menu1: "Schloss Schönbrunn",
  menu2: "Museum MERN",
  menu3: "Über uns",
  about1:
    "Unsere Produkte und Dienstleistungen sind auf Ihre Bedürfnisse als Tourist/-in zugeschnitten und folgen den Trends innovativer digitaler Lösungen im Tourismus.",
  about2:
    "Die mobile Technologie eröffnet Ihnen die Möglichkeit Kunst und Kultur auf vollkommen neue und einfache Art und Weise zu entdecken. Mit nur wenigen Klicks wird Audioguide.hr zu Ihrem persönlichen Reiseführer und Geschichtenerzähler",
  about3:
    "Unsere Mission ist es, digitale Technologien im Tourismus zu implementieren, um die Qualität im Bereich der Dienstleistungen weiter zu steigern und um das Vertrauen und die Zufriedenheit der Nutzer zu gewinnen",
  about4: "Wir wünschen Ihnen einen angenehmen Besuch!",
};
